<template>
  <div class="container">
    <van-row v-if="list.length" class="list">
      <van-col v-for="(item,index) in list" :key="index" span="24">
        <div class="list-item">
          <div class="list-item-header">
            {{ item.course_cate_name }} {{ item.achievement_cate_finish_count }} / {{ item.achievement_cate_count }}
          </div>
          <div v-if="item.achievement_icon.length" class="list-item-inner">
            <van-row gutter="10">
              <van-col v-for="(icon,iconIndex) in item.achievement_icon" :key="iconIndex" span="8">
                <div class="list-item-box" :class="[icon.icon_enable ? '' :'list-item-box-gray']">
                  <van-image class="list-item-box-img" :src="icon.icon_image" />
                  <div class="list-item-box-title van-multi-ellipsis--l2">{{ icon.icon_name }}</div>
                </div>
              </van-col>
            </van-row>
          </div>
        </div>
      </van-col>
    </van-row>
    <van-empty
      v-if="list.length == 0 && !searchLoading"
      :image="no_data"
      :description="noTips"
    />
    <loadings :loading='searchLoading' />
  </div>
</template>
<script>
import { Empty } from 'vant'
import no_data from '@/assets/no_data.png'
import PageMixin from '@/mixins/page'
import Loadings from '@/components/loading'

export default {
  name: 'StudentAchievement',
  components: {
    [Empty.name]: Empty,
    loadings:Loadings,
  },
  mixins: [PageMixin],
  data() {
    return {
      list: [],
      searchLoading:false,
      noTips: '您还没有成就哦~',
      no_data
    }
  },
  created() {
    this.searchLoading = true
    this.$api.achievement_index().then(res => {
      this.list = res.data
      this.searchLoading = false
    })
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>

  .list{
    padding: 10px 15px;
    &-item{
      &-header{
        padding: 5px 10px 6px;
        font-size: 18px;
        font-weight: bold;
        color: #333;
      }
      &-inner{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 5px 10px;
        /deep/ .van-row{
          width: 100%;
        }
      }
      &-box{
        background: #fff;
        border-radius: 4px;
        overflow: hidden;
        margin-bottom: 10px;
        box-shadow: 0px 2px 4px 2px rgba(228, 228, 228, 0.5);
        &-img{
          width: 100px;
          height: 100px;
        }
        &-title{
          line-height: 1.8;
          padding: 0 5px;
          font-size: 14px;
          color: #666;
          min-height: 50px;
        }
        &-gray{
          position: none;
          opacity: .5;
        }
      }
    }
  }
</style>

